export class updatable {
    constructor() {
        this.country_id = null;
        this.university_id = null;
        this.faculty = null;
        this.program = null;
        this.level = null;
        this.yoksis_unit_id = null;
        this.unit_id = null;
        this.entry_type = null;
        this.entry_score = null;
        this.gno = null;
        this.finished_class = null;
        this.semester_count = null;
        this.registration_date = null;
        this.proficiency_status = null;
        this.before_undergraduate_transfer = null;
        this.before_undergraduate_transfer_type = null;
    }
}

export class status {
    constructor() {
        this.country_id = false;
        this.university_id = false;
        this.faculty = false;
        this.program = false;
        this.level = false;
        this.yoksis_unit_id = false;
        this.unit_id = false;
        this.entry_type = false;
        this.entry_score = false;
        this.gno = false;
        this.finished_class = false;
        this.semester_count = false;
        this.registration_date = false;
        this.proficiency_status = false;
        this.before_undergraduate_transfer = false;
        this.before_undergraduate_transfer_type = false;
    }
}
