<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('undergraduate_transfer_applications')" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event"
                    :actionButtons="placementStatus ? datatable.actionButtons : []">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('undergraduate_transfer_applications')" :isColumns="true"
                    :actionButtons="placementStatus ? datatable.actionButtons : []"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="true" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id" />
                        </b-form-group>
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="undergraduate_transfer_statuses"
                                v-model="datatable.queryParams.filter.status" />
                        </b-form-group>
                        <b-form-group :label="$t('application_type')">
                            <parameter-selectbox code="undergraduate_transfer_types"
                                v-model="datatable.queryParams.filter.transfer_type" />
                        </b-form-group>
                        <b-form-group :label="$t('archive')">
                            <b-form-select v-model="datatable.queryParams.filter.is_archived"
                                :options="[{ text: $t('all'), value: null }, { text: $t('yes'), value: true }, { text: $t('no'), value: false }]"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.national_id"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input v-model="datatable.queryParams.filter.passport_number"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <legend tabindex="-1" class="col-form-label">
                            {{ $t('prefers') }}
                        </legend>
                        <div class="border rounded-sm p-3">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="datatable.queryParams.filter.preference_faculty_code"
                                    :level="'OL,L'" />
                            </b-form-group>
                            <b-form-group :label="$t('program')" class="mb-1">
                                <program-selectbox v-model="datatable.queryParams.filter.preference_program_code"
                                    :faculty_code="datatable.queryParams.filter.preference_faculty_code" />
                            </b-form-group>
                            <b-form-group :label="$t('result')">
                                <b-form-select v-model="datatable.queryParams.filter.result" :options="[{ text: $t('application_result.placed'), value: 'placed' },
                                { text: $t('application_result.not_placed'), value: 'not_placed' }]" />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="showModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" :footer="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId" v-model="formData" v-if="formProcess == 'show'" @update="getRows" />
                </template>
                <template v-slot:CommonModalFooter>
                    <div class="d-flex">
                        <b-button variant="primary" @click="updateForm(formData)">
                            {{ $t('update') }}
                        </b-button>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="updateModalPrepSchool" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t('update').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateFormPrepSchool :formId="formId" :formData="formData"
                        v-if="formProcess == 'updateModalPrepSchool'" @updated="updatedPrepSchool" />
                </template>
            </CommonModal>
            <CommonModal ref="rejectModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('reject').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="rejectModalValidate">
                        <ValidationProvider name="explanation" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('explanation')">
                                <b-form-textarea v-model="reject.explanation" :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                    <b-button variant="danger" block @click="rejectForm">{{ $t('reject') }}</b-button>
                </template>
            </CommonModal>
            <CommonModal ref="deleteModal" :onHideOnlyX="true">
                <template v-slot:CommonModalContent>
                    <h6 class="mb-5">{{ $t('are_you_sure_to_delete') }}</h6>
                    <div class="d-flex flex-row align-items-center justify-content-center">

                        <b-button variant="primary" class="mr-5" block
                            @click="$refs.deleteModal.$refs.commonModal.hide()">{{ $t('close')
                            }}</b-button>
                        <b-button variant="danger" class="ml-5" block @click="deleteForm">{{ $t('delete') }}</b-button>
                    </div>

                </template>
            </CommonModal>
            <CommonModal ref="messageModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('bulk_message').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <message-form @send="sendMessage" />
                </template>
            </CommonModal>
            <CommonModal ref="statusUpdateModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('new_status_radio').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="statusUpdateModalValidate">
                        <ValidationProvider name="transferUpdateStatus" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('status_update')">
                                <b-form-select v-model="transferUpdateStatus" :options="transferUpdateOptions"
                                    :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                    <b-button variant="danger" block @click="statusUpdate">{{ $t('save') }}</b-button>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
import MessageForm from './MessageForm'

// Services
import UndergraduateTransferService from '@/services/UndergraduateTransferService';
import UndergraduateTransferPreferenceService from '@/services/UndergraduateTransferPreferenceService';
import ConstantService from '@/services/ConstantService';

// Page
import ShowForm from './ShowForm';

// Other
import qs from 'qs';

export default {
    name: "UndergraduateTransferAdmin",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        FacultySelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        ParameterSelectbox,
        ShowForm,
        MessageForm,
        UpdateFormPrepSchool: () => import('./approve/studentAffairs/UpdateForm'),
    },
    metaInfo() {
        return {
            title: this.$t('undergraduate_transfer_applications').toUpper()
        };
    },
    data(vm) {
        return {
            formId: null,
            formData: {},
            formProcess: null,
            transferUpdateStatus: "",
            placementStatus: false,
            transferUpdateOptions: [{
                text: vm.$t('student_affairs'),
                value: "documents_approved"
            },
            {
                text: vm.$t('waiting_prep_school'),
                value: "waiting_prep_school"
            },
            {
                text: vm.$t('waiting_academic_unit_office'),
                value: "waiting_academic_unit_office"
            }],
            reject: {
                undergraduate_transfer_id: null,
                explanation: null,
            },
            delete: {
                undergraduate_transfer_id: null,
            },
            subMenu: [
                {
                    text: this.$t('bulk_message'),
                    class: 'ri-device-recover-line',
                    callback: () => {
                        this.$refs.messageModal.$refs.commonModal.show()
                    }
                },
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'undergraduatetransfer_show',
                                callback: (row) => {
                                    this.showForm(row);
                                }
                            },
                            {
                                text: this.$t('reject'),
                                class: 'ri-close-line',
                                permission: 'undergraduatetransfer_decline',
                                callback: (row) => {
                                    this.rejectFormShow(row);
                                }
                            },
                            {
                                text: this.$t('application_delete'),
                                class: 'ri-delete-bin-line',
                                permission: 'undergraduatetransfer_delete',
                                callback: (row) => {
                                    this.deleteFormShow(row);
                                }
                            },
                            {
                                text: this.$t('message_send'),
                                class: 'ri-message-2-line',
                                permission: 'undergraduatetransfer_sendmessageforstudentaffairs',
                                callback: (row) => {
                                    this.messageModalHandler(row);
                                }
                            },
                            {
                                text: this.$t('status_update'),
                                class: 'ri-message-2-line',
                                permission: 'undergraduatetransfer_changestatus',
                                callback: (row) => {
                                    this.statusUpdateModalHandler(row);
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                        hidden: true
                    },
                    {
                        label: this.$t('semester').toUpper(),
                        field: 'semester',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.semester.academic_year + ' / ' + this.getSemester(row.semester.semester);
                        }
                    },
                    {
                        label: this.$t('national_id').toUpper(),
                        field: 'national_id',
                        sortable: false,
                        hidden: true,
                    },
                    {
                        label: this.$t('passport_number').toUpper(),
                        field: 'passport_number',
                        sortable: false,
                        hidden: true,
                    },
                    {
                        label: this.$t('name').toUpper() + ' ' + this.$t('surname').toUpper(),
                        field: 'name',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.name + ' ' + row.surname;
                        }
                    },
                    {
                        label: this.$t('preferences').toUpper(),
                        field: 'preferences',
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = '';
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">'
                                value.forEach((item) => {
                                    html += '<li>';
                                    if (item.faculty) html += this.getLocaleText(item.faculty, 'name') + '<br>';
                                    if (item.program) html += this.getLocaleText(item.program, 'name') + '<br>';
                                    html += '</li>';
                                })
                                html += '</ol>'
                            }
                            return html;
                        }
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getLocaleText(row, 'status_text');
                        }
                    },
                    {
                        label: this.$t('result').toUpper(),
                        field: 'result',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (!row.result) return '-'
                            let variant = ['placed'].includes(row.result) ? 'badge-success'
                                : row.result == 'not_placed' ? 'badge-danger' : 'badge-warning'

                            return (
                                `<span class="badge ${variant}">${this.$t(`application_result.${row.result}`)}</span>`);
                        },
                    },
                    {
                        label: this.$t('explanation').toUpper(),
                        field: 'explanation',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.explanation ? ` ${row.explanation}` : ""}`
                        }

                    },
                    {
                        label: this.$t('last_message').toUpper(),
                        field: 'last_message',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.last_message ? ` ${row.last_message}` : ""}`
                        }

                    },
                ],
                actionButtons: [{
                    text: this.$t('placement_student_result'),
                    callback: async () => {
                        const response = await UndergraduateTransferService.placedStudentExcelExport()
                        if (response.status === 200) {
                            this._downloadFile(response, `${this.$t('placement_student_result')}.xlsx`)
                        } else {
                            this.$toast.error(this.$t('something_went_wrong'))
                        }
                    }
                }],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        is_archived: false
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        async getData() {
            const config = {
                params: {
                    filter: {
                        id: this.formId
                    },
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            const response = await UndergraduateTransferService.getAll(config)

            if (response.data.success && response.data.data.length > 0) {
                this.formData = response.data.data[0]

                const index = this.datatable.rows.findIndex(item => item.id === this.formId)
                if (index > -1) {
                    this.datatable.rows[index] = response.data.data[0]
                }
            }
        },
        async statusUpdate() {
            const valid = await this.$refs.statusUpdateModalValidate.validate();
            if (!valid) {
                return
            }

            let formData = {
                status: this.transferUpdateStatus,
                undergraduate_transfer_id: this.formId
            }

            const response = await UndergraduateTransferService.changeStatus(formData)
                .catch((e) => {
                    this.showErrors(e)
                })

            if (response.data.success) {
                this.$refs.statusUpdateModal.$refs.commonModal.hide()
                this.transferUpdateStatus = null
                this.$toast.success(this.$t("api." + response.data.message));
                this.getRows();
            }
        },
        async exportExcel() {
            const config = {
                params: {
                    filter: {
                        ...this.datatable.queryParams.filter
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            const response = await UndergraduateTransferService.excelReport(config)

            if (response?.status === 200) {
                this._downloadFile(response, `${this.$t('student_affairs')}-${this.$t('undergraduate_transfer_applications')}-${this.$t('excel')}.xlsx`)
                this.$toast.success(`${this.$t('excel')} ${this.$t('downloaded')}`);
            }
        },
        updatedPrepSchool() {
            this.$refs.updateModalPrepSchool.$refs.commonModal.hide()
            this.getRows()
        },
        updateForm(row) {
            this.formId = row.id
            this.formData = row
            this.formProcess = 'updateModalPrepSchool'
            this.$refs.showModal.$refs.commonModal.hide();
            this.$refs.updateModalPrepSchool.$refs.commonModal.show()
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return UndergraduateTransferService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total

                    if (this.formId) {
                        const row = this.datatable.rows.find(item => item.id === this.formId)
                        this.formData = row
                    }
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Form
        formClear() {
            this.formId = null
            this.formData = {}
            this.formProcess = null
            this.getRows();
        },
        showForm(row) {
            this.formId = row.id
            this.formData = row
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        rejectFormShow(row) {
            this.$refs.rejectModal.$refs.commonModal.show()
            this.reject.undergraduate_transfer_id = row.id
        },
        deleteFormShow(row) {
            this.$refs.deleteModal.$refs.commonModal.show()
            this.delete.undergraduate_transfer_id = row.id
        },
        statusUpdateModalHandler(row) {
            this.formId = row.id
            this.$refs.statusUpdateModal.$refs.commonModal.show()
        },
        messageModalHandler(row) {
            this.formId = row.id
            this.$refs.messageModal.$refs.commonModal.show()
        },
        async sendMessage(message) {
            let formData = {
                message: message,
                undergraduate_transfer_id: this.formId
            }

            const response = await UndergraduateTransferService.sendMessageStudentAffairs(formData)
                .catch((e) => {
                    this.showErrors(e)
                })

            if (response.data.success) {
                this.$refs.messageModal.$refs.commonModal.hide()
                this.$toast.success(this.$t("api." + response.data.message));
                this.getRows();
            }
        },
        async deleteForm() {
            UndergraduateTransferService.transferDelete(this.delete)
                .then(response => {
                    this.getRows();
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$refs.deleteModal.$refs.commonModal.hide()
                    this.delete = {}
                })
                .catch(error => {
                    this.$toast.error(this.$t('api.' + error.data.message));
                });
        },
        async rejectForm() {
            const isValid = await this.$refs.rejectModalValidate.validate();
            if (!isValid) return
            UndergraduateTransferPreferenceService.transferDecline(this.reject)
                .then(response => {
                    this.getRows();
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$refs.rejectModal.$refs.commonModal.hide()
                    this.reject = {}
                })
                .catch(error => {
                    this.$toast.error(this.$t('api.' + error.data.message));
                });
        },
        async placementResultStatus() {
            const response = await ConstantService.getValuePrivate("undergraduate_transfer.place_result")
                .catch((e) => {
                    this.showErrors(e);
                })
            if (response.data.success) {
                this.placementStatus = response.data.data == "true"
            }
        },
    },
    mounted() {
        this.placementResultStatus()
    }
}
</script>
