<template>
    <div>
        <div class="mb-4">
            <info-collapse-area>
                <template v-slot:title>
                    <div class="w-100 d-flex flex-column flex-md-row justify-content-md-between">
                        <div class="mt-1 mt-md-0 mb-1 mb-md-0 fs-16">
                            {{ getObjectValue(application, 'name') }} {{ getObjectValue(application, 'surname') }}
                        </div>
                        <div class="mb-1 mb-md-0 mr-0 mr-md-4">
                            {{ getLocaleText(application, 'status_text') }}
                        </div>
                    </div>
                </template>
            </info-collapse-area>
        </div>

        <div class="d-flex">
            <div class="mr-3" style="flex-basis: 250px; flex-shrink: 0">
                <div v-for="(menu, menuKey) in menus" :key="menuKey">
                    <b-button
                        variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left mt-0 mb-1"
                        :class="[menuSelected === menu.slug ? 'btn-primary' : 'secondary-border-lighter btn-outline-secondary']"
                        @click="menuChange(menu.slug)">
                        {{ menu.title.toUpper() }}
                    </b-button>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="border rounded-sm p-4" v-show="menuSelected == 'personal_information'">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('nationality')">
                                <div class="label-as-input">{{ getObjectValue(application, 'nationality_code') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('national_id')" v-if="application.nationality_code == 'TR'">
                                <div class="label-as-input">{{ getObjectValue(application, 'national_id') }}</div>
                            </b-form-group>
                            <b-form-group :label="$t('passport_number')" v-else>
                                <div class="label-as-input">{{ getObjectValue(application, 'passport_number') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('name')">
                                <div class="label-as-input">{{ getObjectValue(application, 'name') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('surname')">
                                <div class="label-as-input">{{ getObjectValue(application, 'surname') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('father_name')">
                                <div class="label-as-input">{{ getObjectValue(application, 'father_name') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('gender')">
                                <div class="label-as-input">{{ getObjectValue(application, 'gender') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('birthdate')">
                                <div class="label-as-input">{{ toLocaleDate(application.birthdate) }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('birthplace')">
                                <div class="label-as-input">{{ getObjectValue(application, 'birthplace') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'contact_information'">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group :label="$t('address')">
                                <div class="label-as-input">
                                    {{ getObjectValue(application, 'address') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('email')">
                                <div class="label-as-input">{{ getObjectValue(application, 'email') }}</div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('telephone')">
                                <div class="label-as-input">{{ getObjectValue(application, 'phone') }}</div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4 education_information" v-show="menuSelected == 'education_information'">
                    <ValidationObserver ref="form">
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('country')">
                                    <div class="label-as-input" v-if="!updateStatus.country_id">
                                        {{ getLocaleText(application.country, 'name') }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.country_id = !updateStatus.country_id"></i>
                                    </div>
                                    <ValidationProvider name="country_id" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <country-selectbox v-model="updatable.country_id"
                                                :validateError="errors[0]"></country-selectbox>
                                            <i class="ri-save-line" @click="updateField('country_id')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('university')">
                                    <div class="label-as-input" v-if="!updateStatus.university_id">
                                        <span v-if="application.university">
                                            {{ getLocaleText(application.university, 'name') }}
                                        </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.university_id = !updateStatus.university_id"></i>
                                    </div>
                                    <ValidationProvider name="university_id" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <university-selectbox :countryId="(application.country || -1).id"
                                                :isCountryRequired="true" v-model="updatable.university_id"
                                                :validateError="errors[0]">
                                            </university-selectbox>
                                            <i class="ri-save-line" @click="updateField('university_id')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('faculty')">
                                    <div class="label-as-input" v-if="!updateStatus.faculty">
                                        {{ getObjectValue(application, 'faculty') }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.faculty = !updateStatus.faculty"></i>
                                    </div>
                                    <ValidationProvider name="faculty" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input v-model="updatable.faculty" :state="errors[0] ? false : null"
                                                    :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>

                                            <i class="ri-save-line" @click="updateField('faculty')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('program')">
                                    <div class="label-as-input" v-if="!updateStatus.program">
                                        {{ getObjectValue(application, 'program') }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.program = !updateStatus.program"></i>
                                    </div>
                                    <ValidationProvider name="program" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input v-model="updatable.program" :state="errors[0] ? false : null"
                                                    :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('program')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group v-if="!application.university">
                                    <button type="button" @click="updateForm" class="btn btn-primary">{{
                                        toUpperCase('university_add') }}</button>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('level')">
                                    <div class="label-as-input" v-if="!updateStatus.level">
                                        <span>{{ $t(`program_level_${(application.level || "").toLowerCase()}`) }} </span>
                                        <i class="ri-edit-box-line" @click="updateStatus.level = !updateStatus.level"></i>
                                    </div>
                                    <ValidationProvider name="level" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <ProgramLevelSelectboxOLLYLD v-model="updatable.level"
                                                :validate-error="errors[0]" :setActive="true" />
                                            <i class="ri-save-line" @click="updateField('level')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('yoksis_student_id')" v-if="application.type != 'abroad'">
                                    <div class="label-as-input" v-if="!updateStatus.yoksis_unit_id">
                                        <span> {{ getObjectValue(application, 'yoksis_unit_id') }} </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.yoksis_unit_id = !updateStatus.yoksis_unit_id"></i>
                                    </div>
                                    <ValidationProvider name="yoksis_unit_id" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input v-model="updatable.yoksis_unit_id"
                                                    :state="errors[0] ? false : null" :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('yoksis_unit_id')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('yoksis_unit_id')" v-if="application.type != 'abroad'">
                                    <div class="label-as-input" v-if="!updateStatus.unit_id">
                                        <span> {{ getObjectValue(application, 'unit_id') }} </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.unit_id = !updateStatus.unit_id"></i>
                                    </div>
                                    <ValidationProvider name="unit_id" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input v-model="updatable.unit_id" :state="errors[0] ? false : null"
                                                    :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('unit_id')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('input_type')" v-if="application.type !== 'abroad'">
                                    <div class="label-as-input" v-if="!updateStatus.entry_type">
                                        {{ getObjectValue(application, 'entry_type') }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.entry_type = !updateStatus.entry_type"></i>
                                    </div>
                                    <ValidationProvider name="entry_type" rules="required" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <true-false-selectbox v-model="updatable.entry_type" :options="entryTypeOptions"
                                                :validate-error="errors[0]" />
                                            <i class="ri-save-line" @click="updateField('entry_type')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('entry_score')">
                                    <div class="label-as-input" v-if="!updateStatus.entry_score">
                                        <span> {{ (Number(getObjectValue(application, 'entry_score')) || 0).toFixed(2) }}
                                        </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.entry_score = !updateStatus.entry_score"></i>
                                    </div>
                                    <ValidationProvider name="entry_score" rules="required|double" v-slot="{ errors }"
                                        v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input type="number" v-model="updatable.entry_score"
                                                    :state="errors[0] ? false : null" :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('entry_score')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('gno')">
                                    <div class="label-as-input" v-if="!updateStatus.gno">
                                        <span> {{ getObjectValue(application, 'gno') }} </span>
                                        <i class="ri-edit-box-line" @click="updateStatus.gno = !updateStatus.gno"></i>
                                    </div>
                                    <ValidationProvider name="gno" rules="required|double" v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input type="number" v-model="updatable.gno"
                                                    :state="errors[0] ? false : null" :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('gno')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('finished_class')" v-if="application.type !== 'abroad'">
                                    <div class="label-as-input" v-if="!updateStatus.finished_class">
                                        <span> {{ getObjectValue(application, 'finished_class') }} </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.finished_class = !updateStatus.finished_class"></i>
                                    </div>
                                    <ValidationProvider name="finished_class" rules="required|double" v-slot="{ errors }"
                                        v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <b-form-input type="number" v-model="updatable.finished_class"
                                                    :state="errors[0] ? false : null" :validateError="errors[0]" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('finished_class')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('number_of_semesters')">
                                    <div class="label-as-input" v-if="!updateStatus.semester_count">
                                        <span> {{ application.semester_count ? $t('semester_x', {
                                            semester: application.semester_count
                                        })
                                            : '-' }} </span>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.semester_count = !updateStatus.semester_count"></i>
                                    </div>
                                    <ValidationProvider name="semester_count" rules="required|double" v-slot="{ errors }"
                                        v-else>
                                        <div class="label-as-input">
                                            <b-form-input type="number" v-model="updatable.semester_count"
                                                :state="errors[0] ? false : null" :validateError="errors[0]" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            <i class="ri-save-line" @click="updateField('semester_count')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('registration_date')">
                                    <div class="label-as-input" v-if="!updateStatus.registration_date">
                                        {{ toLocaleDate(getObjectValue(application, 'registration_date')) }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.registration_date = !updateStatus.registration_date"></i>
                                    </div>
                                    <ValidationProvider name="registration_date" rules="required" v-slot="{ errors }"
                                        v-else>
                                        <div class="label-as-input">
                                            <div>
                                                <select-date v-model="updatable.registration_date"
                                                    :state="errors[0] ? false : null" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </div>
                                            <i class="ri-save-line" @click="updateField('registration_date')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('proficiency_status')">
                                    <div class="label-as-input" v-if="!updateStatus.proficiency_status">
                                        <div v-if="isNotNull(application.proficiency_status)">
                                            {{ application.proficiency_status == "E" ? $t('studied') : $t('not_studied') }}
                                        </div>
                                        <div v-else>-</div>
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.proficiency_status = !updateStatus.proficiency_status"></i>
                                    </div>
                                    <ValidationProvider name="proficiency_status" rules="required" v-slot="{ errors }"
                                        v-else>
                                        <div class="label-as-input">
                                            <yes-no-selectbox v-model="updatable.proficiency_status"
                                                :validateError="errors[0]" />
                                            <i class="ri-save-line" @click="updateField('proficiency_status')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('is_before_undergraduate_transfer')">
                                    <div class="label-as-input" v-if="!updateStatus.before_undergraduate_transfer">
                                        {{ application.before_undergraduate_transfer ? $t('yes') : $t('no') }}
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.before_undergraduate_transfer = !updateStatus.before_undergraduate_transfer"></i>
                                    </div>
                                    <ValidationProvider name="before_undergraduate_transfer" rules="required"
                                        v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <true-false-selectbox v-model="updatable.before_undergraduate_transfer"
                                                :options="trueFalseOptions" :validate-error="errors[0]" />
                                            <i class="ri-save-line"
                                                @click="updateField('before_undergraduate_transfer')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                                <b-form-group :label="$t('is_before_undergraduate_transfer_type')"
                                    v-if="application.before_undergraduate_transfer">
                                    <div class="label-as-input" v-if="!updateStatus.before_undergraduate_transfer_type">
                                        <parameter-selectbox code="undergraduate_transfer_types" :disabled="true"
                                            :public="true" v-model="application.before_undergraduate_transfer_type" />
                                        <i class="ri-edit-box-line"
                                            @click="updateStatus.before_undergraduate_transfer_type = !updateStatus.before_undergraduate_transfer_type"></i>
                                    </div>
                                    <ValidationProvider name="before_undergraduate_transfer_type" rules="required"
                                        v-slot="{ errors }" v-else>
                                        <div class="label-as-input">
                                            <parameter-selectbox code="undergraduate_transfer_types"
                                                :validate-error="errors[0]" :public="true"
                                                v-model="updatable.before_undergraduate_transfer_type" />
                                            <i class="ri-save-line"
                                                @click="updateField('before_undergraduate_transfer_type')"></i>
                                        </div>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'preferences'">
                    <h6 class="mb-2">{{ $t('preferences') }}</h6>
                    <b-table :empty-text="$t('there_are_no_records')" bordered striped responsive show-empty
                        :fields="preferencesFields" :items="preferences" sort-by="code" style="overflow: visible;"
                        class="mb-0 border rounded">
                        <template #cell(rank)="row">
                            <div style="font-size: 16px;"
                                class="d-flex align-items-center justify-content-center table-preferences">
                                <span class="mr-1">{{ row.item.rank }}</span>
                                <template v-if="preferences.length > 1">
                                    <i @click="changeRank(row.item.rank)" v-if="row.index < 1" class="ri-arrow-down-line"
                                        style="font-size: 22px;"></i>
                                    <i @click="changeRank(row.item.rank)" v-else-if="row.index > 0" class="ri-arrow-up-line"
                                        style="font-size: 22px;"></i>
                                </template>
                            </div>
                        </template>
                        <template #cell(faculty_program)="row">
                            {{ getLocaleText(row.item.faculty, 'name') }} <br>
                            {{ getLocaleText(row.item.program, 'name') }}
                        </template>
                        <template #cell(type)="row">
                            <div class="d-flex justify-content-between align-items-center transfer_types_container">
                                <template v-if="!row.item.type_edit_status">
                                    <span> {{ getLocaleText(row.item, 'transfer_type_text') }}</span>
                                    <span @click="typeChange(row.item)"><i class="ri-edit-box-line" style="font-size: 20px;"
                                            v-b-tooltip.hover :title="$t('edit')"></i></span>
                                </template>
                                <template v-else>
                                    <ValidationObserver :ref="`form-type-${row.item.id}`">
                                        <ValidationProvider name="preferenceNewType" rules="required" v-slot="{ errors }">
                                            <parameter-selectbox v-model="preferenceNewType" :validateError="errors[0]"
                                                code="undergraduate_transfer_types"></parameter-selectbox>
                                        </ValidationProvider>
                                    </ValidationObserver>
                                    <span @click="typeChange(row.item, 'save')"><i class="ri-save-line ml-2"
                                            style="font-size: 20px;" v-b-tooltip.hover :title="$t('save')"></i></span>
                                </template>
                            </div>
                        </template>
                        <template #cell(prep_status)="row">
                            <template v-if="row.item.prep_status !== null">
                                {{ row.item.prep_status ? $t('success') : $t('unsuccessful') }}
                            </template>
                            <template v-else>
                                {{ prepStatusText(row.item.prep_status) }}
                            </template>
                        </template>
                        <template #cell(class)="row">
                            <template v-if="row.item.result == 'placed'">
                                {{ classNames[row.item.class] || "-" }}
                            </template>
                            <template v-else>-</template>
                        </template>
                        <template #cell(status)="row">
                            <div class="d-flex justify-content-between align-items-center">
                                {{ getLocaleText(row.item, 'status_text') }}
                                <template v-if="row.item.status !== 'waiting'">
                                    <span v-b-tooltip.hover :title="$t('reset')"
                                        @click="preferenceResetModalhandler(row.item)">
                                        <i class="ri-arrow-go-back-fill" style="font-size: 20px;"></i></span>
                                </template>
                            </div>
                        </template>
                        <template #cell(result)="row">
                            <template v-if="!row.item.result">-</template>
                            <template v-else>
                                <span class="badge" :class="['placed', 'other_preference_placed'].includes(row.item.result) ? 'badge-success'
                                    : row.item.result == 'not_placed' ? 'badge-danger' : 'badge-warning'">
                                    {{ $t(`application_result.${row.item.result}`) }}
                                </span>
                            </template>
                        </template>
                        <template #cell(status_update)="row">
                            <div class="d-flex justify-content-center" v-if="row.item.status !== 'declined'">
                                <b-button variant="danger" size="md" @click="rejectForm(row.item)" class="mr-1"
                                    style="border:1px solid #d9d9d9 !important;">
                                    {{ $t('reject') }}
                                </b-button>
                            </div>
                            <span v-else>{{ $t('reason') }}: {{ row.item.explanation }}</span>
                        </template>
                    </b-table>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'documents'">
                    <h6 class="mb-2">{{ $t('documents') }}</h6>
                    <span v-if="!application.documents"> {{ $t('document_not_found') }}
                    </span>
                    <b-table v-else :empty-text="$t('there_are_no_records')" bordered striped responsive show-empty
                        :fields="documentFields" :items="documents" sort-by="code" class="mb-0 border rounded">
                        <template #cell(rank)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(faculty_program)="row">
                            {{ getLocaleText(row.item, 'faculty_name') }} <br>
                            {{ getLocaleText(row.item, 'program_name') }}
                        </template>
                        <template #cell(status)="row">
                            <template v-if="row.item.status">
                                <span v-if="row.item.status == 'uploaded'">
                                    {{ $t('status_approval_awaited') }}
                                </span>
                                <span v-else>
                                    {{ $t('status_' + row.item.status) }}
                                </span>
                            </template>
                        </template>
                        <template #cell(processes)="row">
                            <div class="d-flex justify-content-center" v-if="row.item.status">
                                <b-button
                                    :variant="['uploaded', 'declined'].includes(row.item.status) ? 'light' : 'success'"
                                    size="sm" @click="changeStatus(row.item, row.item.key, 'approved')" class="mr-1"
                                    style="border:1px solid #d9d9d9 !important;"
                                    v-if="['uploaded', 'declined', 'approved'].includes(row.item.status)">
                                    <i class="ri-check-line"></i>
                                </b-button>
                                <b-button :variant="['uploaded', 'approved'].includes(row.item.status) ? 'light' : 'danger'"
                                    size="sm" @click="changeStatus(row.item, row.item.key, 'declined')" class="bordered"
                                    style="border:1px solid #d9d9d9 !important;"
                                    v-if="['uploaded', 'declined', 'approved'].includes(row.item.status)">
                                    <i class="ri-close-line"></i>
                                </b-button>
                                <template v-else> - </template>
                            </div>
                        </template>
                        <template #cell(download)="row">
                            <div class="d-flex justify-content-center">
                                <b-button variant="primary" size="sm" @click="downloadDocument(row.item)"
                                    style="border:1px solid #d9d9d9 !important; display: block;"
                                    v-if="['uploaded', 'declined', 'approved'].includes(row.item.status) || row.item.action === 'show'">
                                    <i :class="`${row.item.action === 'show' ? 'ri-eye-line' : 'ri-download-fill'}`"></i>
                                </b-button>
                                <template v-else> - </template>
                            </div>
                        </template>
                    </b-table>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'login_details'">
                    <h6 class="mb-2">{{ $t('login_details') }}</h6>
                    <b-row>
                        <b-col cols="12" md="4" v-if="application.national_id">
                            <b-form-group :label="$t('national_id')">
                                <div class="label-as-input">
                                    {{ application.national_id }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" v-if="application.passport_number">
                            <b-form-group :label="$t('passport_number')">
                                <div class="label-as-input">
                                    {{ application.passport_number }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('pin_code')">
                                <div class="label-as-input">
                                    {{ application.pin }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'osym_data'"
                    style="max-height: 400px; overflow: scroll;">
                    <h6 class="mb-2">{{ $t('osym_data') }}</h6>
                    <b-row>
                        <template v-for="(value, key, index) in osymPoinst">
                            <b-col cols="12" md="4" :key="key + index">
                                <b-form-group :label="key">
                                    <div class="label-as-input">
                                        {{ value }}
                                    </div>
                                </b-form-group>
                            </b-col>
                        </template>
                    </b-row>
                </div>
                <div class="border rounded-sm p-4" v-show="menuSelected == 'explanation'">

                    <b-row>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('status')">
                                <div class="label-as-input">
                                    {{ getLocaleText(application, 'status_text') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('reason')">
                                <div class="label-as-input">
                                    {{ application.explanation }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('last_message')">
                                <div class="label-as-input">
                                    {{ application.last_message }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>

                <CommonModal ref="updateModal" :onHideOnlyX="true">
                    <template v-slot:CommonModalTitle>
                        {{ $t('update').toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <update-form :countryId="application.country.id" :transferId="application.id"
                            @updated="universityUpdate" />
                    </template>
                </CommonModal>

                <CommonModal ref="rejectModal" :onHideOnlyX="true">
                    <template v-slot:CommonModalTitle>
                        {{ $t('reject').toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <reject-form :transferPreferenceId="rejectFrom.preferenceId" @updated="rejectComplete" />
                    </template>
                </CommonModal>

                <CommonModal ref="preferenceResetModal" :onHideOnlyX="true">
                    <template v-slot:CommonModalTitle>
                        {{ $t('reset').toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <span>{{ $t('student_preference_reset_info') }}</span>
                        <b-button variant="danger" class="mt-3" block @click="preferenceReset">{{ $t('reset') }}</b-button>
                    </template>
                </CommonModal>

            </div>
        </div>
    </div>
</template>

<script>
// Component
import InfoCollapseArea from '@/components/elements/InfoCollapseArea'

// Services
import UndergraduateTransferService from '@/services/UndergraduateTransferService'
import ParameterItemService from '@/services/ParameterItemService'
import StudentDocumentService from "@/services/StudentDocumentService";
import { EventBus } from '@/main'

import { updatable, status } from "./constant"

export default {
    name: "UnderGraduateTransferAdminShowForm",
    components: {
        InfoCollapseArea,
        UpdateForm: () => import("./UpdateForm"),
        RejectForm: () => import("./RejectForm")
    },
    props: {
        formId: {
            type: Number,
            default: null
        },
        value: {
            type: Object,
            default: () => { }
        }
    },
    created() {
        this.getClassName()
    },
    computed: {
        application: {
            get() {
                return this.value
            },
            set(val) {
                const obj = { ...this.value, ...val }
                this.$emit('input', obj)
                return obj
            }
        },
        documents() {
            const doc = this.objectToArrayObject(this.application.documents)
            if (this.application.type === "in") {
                const student_program_id = this.application.student_program_id
                doc.push({
                    key: "student_transcript",
                    name: "Transkript Belgesi",
                    name_en: "Transcript Document",
                    student_program_id,
                    action: "show",
                })
                doc.push({
                    key: "student_english_language_proficiency_document",
                    name: "İngilizce Dil Yeterlilik Belgesi",
                    name_en: "English Language Proficiency Document",
                    student_program_id,
                    action: "show"
                })
            }
            return doc
        },
        preferences() {
            return this.application.preferences.map(item => ({ ...item, type_edit_status: false })).sort((x, y) => x.rank - y.rank)
        },
        osymPoinst() {
            return JSON.parse(this.application.points)
        }
    },
    data() {
        return {
            menus: [
                {
                    title: this.$i18n.t('personal_information'),
                    slug: 'personal_information',
                },
                {
                    title: this.$i18n.t('contact_information'),
                    slug: 'contact_information',
                },
                {
                    title: this.$i18n.t('education_info'),
                    slug: 'education_information',
                },
                {
                    title: this.$i18n.t('preferences'),
                    slug: 'preferences',
                },
                {
                    title: this.$i18n.t('documents'),
                    slug: 'documents',
                },
                {
                    title: this.$i18n.t('login_details'),
                    slug: 'login_details',
                },
                {
                    title: this.$i18n.t('osym_data'),
                    slug: 'osym_data',
                },
                {
                    title: this.$i18n.t('explanation'),
                    slug: 'explanation',
                },
            ],
            classNames: {},
            menuSelected: 'personal_information',
            preferencesFields: [
                {
                    key: 'rank',
                    label: this.toUpperCase('rank'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                },
                {
                    key: 'faculty_program',
                    label: this.toUpperCase('faculty') + ' / ' + this.toUpperCase('program'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'type',
                    label: this.toUpperCase('type'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'prep_status',
                    label: this.toUpperCase('prep_exam_status'),
                    sortable: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'class',
                    label: this.toUpperCase('class'),
                    sortable: true,
                    tdClass: 'align-middle'
                },
                {
                    key: 'status',
                    label: this.toUpperCase('status'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'result',
                    label: this.toUpperCase('result'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'status_update',
                    label: this.toUpperCase('status_update'),
                    sortable: true,
                    tdClass: 'align-middle',
                }
            ],
            documentFields: [
                {
                    key: 'rank',
                    label: this.toUpperCase('rank'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                },
                {
                    key: 'name',
                    label: this.toUpperCase('name'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'status',
                    label: this.toUpperCase('status'),
                    sortable: true,
                    tdClass: 'align-middle',
                },
                {
                    key: 'processes',
                    label: this.toUpperCase('processes'),
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'download',
                    label: this.toUpperCase('download'),
                    sortable: false,
                }
            ],
            rejectFrom: {
                preferenceId: 0
            },
            preferenceNewType: null,
            updatable: {},
            updateStatus: {},
            trueFalseOptions: [
                { value: null, text: '' },
                { value: 1, text: this.$t('yes') },
                { value: 0, text: this.$t('no') }
            ],
            entryTypeOptions: [
                { value: null, text: '' },
                { value: "OSYS", text: this.$t('osym') },
                { value: "YKS", text: this.$t('yks') },
                { value: "DGS", text: this.$t('dgs') },
            ],
            preferenceId: 0
        }
    },
    methods: {
        prepStatusText(value) {
            if (!value && (this.application.status === 'waiting_academic_unit_office' || this.application.status === 'waiting_publish')) {
                return this.$t('exempt')
            }
            return "-"
        },
        preferenceResetModalhandler(row) {
            this.preferenceId = row.id
            this.$refs.preferenceResetModal.$refs.commonModal.show()
        },
        async preferenceReset() {
            const response = await UndergraduateTransferService.revertPreferenceStatus({
                undergraduate_transfer_preference_id: this.preferenceId
            })
                .catch(e => {
                    this.showErrors(e)
                })

            if (response?.data.success) {
                this.$emit("update")
                this.$refs.preferenceResetModal.$refs.commonModal.hide()
                this.preferenceId = null
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        async changeRank(rank) {
            const data = {
                undergraduate_transfer_preference_id: rank,
                undergraduate_transfer_id: this.application.id
            }
            const response = await UndergraduateTransferService.changeRank(data)
                .catch(e => {
                    this.showErrors(e)
                })

            if (response?.data.success) {
                this.$emit("update")
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        async updateField(fieldName) {
            const valid = await this.$refs[`form`].refs[fieldName].validate()
            if (!valid?.valid) return

            const data = { undergraduate_transfer_id: this.application.id, key: fieldName, value: this.updatable[fieldName] }
            const response = await UndergraduateTransferService.undergraduateTransferUpdateFields(data)
                .catch(e => {
                    this.showErrors(e)
                })

            if (response?.data.success) {
                this.$emit("update")
                this.updateStatus[fieldName] = !this.updateStatus[fieldName]
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        async typeChange(row, actionType) {
            if (actionType === "save") {
                const valid = await this.$refs[`form-type-${row.id}`].validate()
                if (valid) {
                    const response = await UndergraduateTransferService.updatePreferenceTransferType({
                        undergraduate_transfer_preference_id: row.id,
                        transfer_type: this.preferenceNewType
                    })
                        .catch(e => {
                            this.showErrors(e, null, true)
                        })

                    if (response.data.success) {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit("update")
                        row.type_edit_status = !row.type_edit_status
                    }
                }
                return
            }
            row.type_edit_status = !row.type_edit_status
        },
        changeStatus(row, type, status) {
            if (row.status === "approved" && status !== "declined") return
            if (this.checkPermission('undergraduatetransfer_approve')) {
                let formData = {
                    undergraduate_transfer_id: this.application.id,
                    type: type,
                    status: status,
                }
                UndergraduateTransferService.approve(formData)
                    .then(() => {
                        this.application.documents[type].status = status;
                        this.$emit('input', this.application)
                    })
                    .catch((e) => {
                        this.showErrors(e)
                    });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        changePreferencesStatus() { },
        updateForm() {
            this.$refs.updateModal.$refs.commonModal.show()
        },
        rejectForm(row) {
            this.rejectFrom.preferenceId = row.id
            this.$refs.rejectModal.$refs.commonModal.show()
        },
        rejectComplete() {
            this.$refs.rejectModal.$refs.commonModal.hide()
            this.$emit('update')
        },
        menuChange(key) {
            this.menuSelected = key;
        },
        downloadDocument(row) {
            if (row.action === "show") {
                if (row.key === "student_transcript") {
                    StudentDocumentService.downloadTranscript(row.student_program_id, this.$i18n.locale)
                        .then(response => {
                            let fileName = row.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                            EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                        })
                } else if (row.key === "student_english_language_proficiency_document") {
                    StudentDocumentService.downloadLanguageProficiency(row.student_program_id, this.$i18n.locale)
                        .then(response => {
                            let fileName = row.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                            EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                        })
                        .catch(e => {
                            this.showErrors(e, null, true)
                        })
                }
                return
            }
            const formData = {
                undergraduate_transfer_id: this.formId,
                type: row.key
            }
            UndergraduateTransferService.downloadDocumentAdmin(formData)
                .then(response => {
                    this._downloadFile(response, row.filename)
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        universityUpdate({ name, name_en }) {
            this.application.university = { name, name_en }
            this.$refs.updateModal.$refs.commonModal.hide()
        },
        getClassName() {
            ParameterItemService.getItems("classes", null)
                .then((response) => {
                    if (response.data.success) {
                        const obj = {}
                        response.data.data.items.forEach(item => {
                            obj[item.code] = item[`name${this.$i18n.locale == "en" ? '_en' : ''}`]
                        })
                        this.classNames = obj
                    }
                })
        }
    },
    mounted() {
        this.updatable = new updatable();
        this.updateStatus = new status();

        const keys = Object.keys(this.application)
        keys.forEach(item => {
            if (this.application[item] !== null && item in this.updatable) {
                this.updatable[item] = this.application[item]
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.education_information,
.table-preferences {
    .label-as-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        i {
            margin-left: 10px;
        }
    }

    div {
        width: 100% !important;
    }

    i {
        font-size: 20px;
        transition: all 0.2s linear;
        cursor: pointer;
    }

    i:hover {
        transform: scale(1.2);
    }

    i:active {
        transform: scale(1.2);
        transform: scale(1.2) translate(0, 3px);
    }
}

.transfer_types_container>span:first-child {
    min-width: 220px;
    width: 100%;
}
</style>